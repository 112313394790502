
module.exports = (function(){
  var d = _eai_d;
  var r = _eai_r;
  window.emberAutoImportDynamic = function(specifier) {
    if (arguments.length === 1) {
      return r('_eai_dyn_' + specifier);
    } else {
      return r('_eai_dynt_' + specifier)(Array.prototype.slice.call(arguments, 1))
    }
  };
  window.emberAutoImportSync = function(specifier) {
    return r('_eai_sync_' + specifier)(Array.prototype.slice.call(arguments, 1))
  };
  function esc(m) {
    return m && m.__esModule ? m : Object.assign({ default: m }, m);
  }
    d('@fortawesome/fontawesome-svg-core', EAI_DISCOVERED_EXTERNALS('@fortawesome/fontawesome-svg-core'), function() { return esc(require('@fortawesome/fontawesome-svg-core')); });
    d('@sentry/browser', EAI_DISCOVERED_EXTERNALS('@sentry/browser'), function() { return esc(require('@sentry/browser')); });
    d('@sentry/core', EAI_DISCOVERED_EXTERNALS('@sentry/core'), function() { return esc(require('@sentry/core')); });
    d('cloud-dashboard/polyfill.js', EAI_DISCOVERED_EXTERNALS('cloud-dashboard/polyfill.js'), function() { return esc(require('cloud-dashboard/polyfill.js')); });
    d('email-addresses', EAI_DISCOVERED_EXTERNALS('email-addresses'), function() { return esc(require('email-addresses')); });
    d('ember-bem-helpers/helpers/bem', EAI_DISCOVERED_EXTERNALS('ember-bem-helpers/helpers/bem'), function() { return esc(require('ember-bem-helpers/helpers/bem')); });
    d('ember-bem-helpers/helpers/block-name', EAI_DISCOVERED_EXTERNALS('ember-bem-helpers/helpers/block-name'), function() { return esc(require('ember-bem-helpers/helpers/block-name')); });
    d('ember-load-initializers', EAI_DISCOVERED_EXTERNALS('ember-load-initializers'), function() { return esc(require('ember-load-initializers')); });
    d('ember-page-title/helpers/page-title', EAI_DISCOVERED_EXTERNALS('ember-page-title/helpers/page-title'), function() { return esc(require('ember-page-title/helpers/page-title')); });
    d('ember-page-title/services/page-title', EAI_DISCOVERED_EXTERNALS('ember-page-title/services/page-title'), function() { return esc(require('ember-page-title/services/page-title')); });
    d('ember-resolver', EAI_DISCOVERED_EXTERNALS('ember-resolver'), function() { return esc(require('ember-resolver')); });
    d('ember-truth-helpers/helpers/and', EAI_DISCOVERED_EXTERNALS('ember-truth-helpers/helpers/and'), function() { return esc(require('ember-truth-helpers/helpers/and')); });
    d('ember-truth-helpers/helpers/eq', EAI_DISCOVERED_EXTERNALS('ember-truth-helpers/helpers/eq'), function() { return esc(require('ember-truth-helpers/helpers/eq')); });
    d('ember-truth-helpers/helpers/gt', EAI_DISCOVERED_EXTERNALS('ember-truth-helpers/helpers/gt'), function() { return esc(require('ember-truth-helpers/helpers/gt')); });
    d('ember-truth-helpers/helpers/gte', EAI_DISCOVERED_EXTERNALS('ember-truth-helpers/helpers/gte'), function() { return esc(require('ember-truth-helpers/helpers/gte')); });
    d('ember-truth-helpers/helpers/is-array', EAI_DISCOVERED_EXTERNALS('ember-truth-helpers/helpers/is-array'), function() { return esc(require('ember-truth-helpers/helpers/is-array')); });
    d('ember-truth-helpers/helpers/is-empty', EAI_DISCOVERED_EXTERNALS('ember-truth-helpers/helpers/is-empty'), function() { return esc(require('ember-truth-helpers/helpers/is-empty')); });
    d('ember-truth-helpers/helpers/is-equal', EAI_DISCOVERED_EXTERNALS('ember-truth-helpers/helpers/is-equal'), function() { return esc(require('ember-truth-helpers/helpers/is-equal')); });
    d('ember-truth-helpers/helpers/lt', EAI_DISCOVERED_EXTERNALS('ember-truth-helpers/helpers/lt'), function() { return esc(require('ember-truth-helpers/helpers/lt')); });
    d('ember-truth-helpers/helpers/lte', EAI_DISCOVERED_EXTERNALS('ember-truth-helpers/helpers/lte'), function() { return esc(require('ember-truth-helpers/helpers/lte')); });
    d('ember-truth-helpers/helpers/not', EAI_DISCOVERED_EXTERNALS('ember-truth-helpers/helpers/not'), function() { return esc(require('ember-truth-helpers/helpers/not')); });
    d('ember-truth-helpers/helpers/not-eq', EAI_DISCOVERED_EXTERNALS('ember-truth-helpers/helpers/not-eq'), function() { return esc(require('ember-truth-helpers/helpers/not-eq')); });
    d('ember-truth-helpers/helpers/or', EAI_DISCOVERED_EXTERNALS('ember-truth-helpers/helpers/or'), function() { return esc(require('ember-truth-helpers/helpers/or')); });
    d('ember-truth-helpers/helpers/xor', EAI_DISCOVERED_EXTERNALS('ember-truth-helpers/helpers/xor'), function() { return esc(require('ember-truth-helpers/helpers/xor')); });
    d('events', EAI_DISCOVERED_EXTERNALS('events'), function() { return esc(require('events')); });
    d('js-logger', EAI_DISCOVERED_EXTERNALS('js-logger'), function() { return esc(require('js-logger')); });
    d('_eai_dyn_@sentry/browser', [], function() { return import('@sentry/browser'); });
})();
